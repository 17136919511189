export const BASE = 8453
export const MODE = 34443

export const addresses = {
    [BASE]: {
        GMX: '0x548f93779fBC992010C07467cBaf329DD5F059B7',
        ETH: '0x4200000000000000000000000000000000000006',
        BTC: '0x1a35EE4640b0A3B87705B0A4B45D227Ba60Ca2ad',
        cbETH: '0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22',
        YFI: '0x9EaF8C1E34F05a589EDa6BAfdF391Cf6Ad3CB239',
        AERO: '0x940181a94A35A4569E4529A3CDfB74e38FD98631',
        MOG: '0x2Da56AcB9Ea78330f947bD57C54119Debda7AF71',
        EURC: '0x60a3E35Cc302bFA44Cb288Bc5a4F316Fdb1adb42',
        cbBTC: '0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf',
        RewardReader: '0xe0A2683F6B2C7278C27506c22CE2dB74aC661362',
        GLP: '0xe771b4E273dF31B85D7A7aE0Efd22fb44BdD0633',
        GlpManager: '0x9fAc7b75f367d5B35a6D6D0a09572eFcC3D406C5',
    },
    [MODE]: {
        GMX: '0x66eEd5FF1701E6ed8470DC391F05e27B1d0657eb',
        ETH: '0x4200000000000000000000000000000000000006',
        BTC: '0xcDd475325D6F564d27247D1DddBb0DAc6fA0a5CF',
        weETH: '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A',
        MODE: '0xDfc7C877a950e49D2610114102175A06C2e3167a',
        RewardReader: '0x3c293f03700f873C8cfee7Faae36E3437B673A50',
        GLP: '0x952AdBB385296Dcf86a668f7eaa02DF7eb684439',
        GlpManager: '0xf9Fc0B2859f9B6d33fD1Cea5B0A9f1D56C258178',
    }
}

export function getAddress(chainId, key) {
    if (!(chainId) in addresses) {
        throw new Error(`Unknown chain ${chainId}`)
    }
    if (!(key in addresses[chainId])) {
        throw new Error(`Unknown address key ${key}`)
    }
    return addresses[chainId][key]
}
